//@ts-check
import "../../resources/css/content/interview.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import PageSegment from "../../components/content/PageSegment";
import { withRouter } from "react-router-dom";

// our team images
import michalvalentikimg from "../../resources/images/content/our-team/michal-valentik.png";
import miroslavkollarimg from "../../resources/images/content/our-team/miroslav-kollar.png";

import linkedin from "../../resources/images/content/our-team/linkedin-logo.svg";

const memberSpecificInfo = {
	"michal-valentik": {
		paragraphs: 9,
		image: michalvalentikimg,
		linkedin: "https://cz.linkedin.com/in/michal-valent%C3%ADk-538109137",
	},
	"miroslav-kollar": {
		paragraphs: 7,
		image: miroslavkollarimg,
		linkedin: "https://cz.linkedin.com/in/miroslavkollar",
	},
};

const Interview = (props) => {
	const renderInterviewQuestions = () => {
		const paragraphs = [];
		for (
			let i = 0;
			i < memberSpecificInfo[props.match.params.member].paragraphs;
			i++
		) {
			paragraphs.push(
				<div key={i} className="interview-title-paragraph">
					<div className="interview-title">
						{props.t(
							`interview.${props.match.params.member}-question-${i}`
						)}
					</div>
					<div
						className="interview-answer"
						dangerouslySetInnerHTML={{
							__html: props.t(
								`interview.${props.match.params.member}-answer-${i}`
							),
						}}
					/>
				</div>
			);
		}
		return paragraphs;
	};

	return (
		<div id="interview">
			<PageSegment>
				<div className="interview-team-member">
					<div></div>
					<div>
						<div className="interview-image-wrapper">
							<img
								className="interview-image"
								src={
									memberSpecificInfo[
										props.match.params.member
									].image
								}
								alt={props.match.params.member}
							/>
						</div>
						<div className="interview-name">
							{props.t(
								`interview.${props.match.params.member}-interview-name`
							)}
						</div>
						<div className="interview-position">
							{props.t(
								`interview.${props.match.params.member}-interview-position`
							)}
						</div>
					</div>
					<div className="interview-linkedin-wrapper">
						<a
							href={
								memberSpecificInfo[props.match.params.member]
									.linkedin
							}
							target={"_blank"}
							rel={"noreferrer"}
						>
							<img
								className="interview-linkedin-image"
								src={linkedin}
								alt={props.match.params.member}
							/>
						</a>
					</div>
				</div>
				<div className="interview-title-paragraph">
					<div className="interview-title">
						{props.t(
							`interview.${props.match.params.member}-title`
						)}
					</div>
					<div className="interview-answer interview-description">
						{props.t(
							`interview.${props.match.params.member}-description`
						)}
					</div>
				</div>
				{renderInterviewQuestions()}
			</PageSegment>
		</div>
	);
};

Interview.propTypes = {
	t: PropTypes.any.isRequired,
	match: PropTypes.any,
};

export default translate(withRouter(Interview));
