import "../../resources/css/components/scroll-to-top-button.css";
import React, { Component } from "react";

const DEFAULT_VISIBILITY = "hidden";
const SCROLL_TRESHOLD = 200;

export class ScrollToTopButton extends Component {
	constructor(props) {
		super(props);
		this.state = { visibility: DEFAULT_VISIBILITY };

		this.updateState = this.updateState.bind(this);
	}

	updateState() {
		// update visibility
		this.setState({
			visibility: window.scrollY > SCROLL_TRESHOLD ? "shown" : "hidden",
		});

		// scroll progress visualisation - update
		var progressPath = document.getElementById("progress-path");
		var pathLength = progressPath.getTotalLength();
		this.setState({
			progress:
				pathLength -
				pathLength *
					((window.scrollY + window.innerHeight) /
						document.body.clientHeight),
		});
		progressPath.style.strokeDashoffset = this.state.progress;
	}

	scrollToTop() {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}

	componentDidMount() {
		// events handlers - init
		window.addEventListener("scroll", this.updateState);

		// scroll progress visualisation - init
		var progressPath = document.getElementById("progress-path");
		var pathLength = progressPath.getTotalLength();
		progressPath.style.transition = progressPath.style.WebkitTransition =
			"none";
		progressPath.style.strokeDasharray = pathLength + " " + pathLength;
		progressPath.style.strokeDashoffset = pathLength;
		progressPath.getBoundingClientRect();
		progressPath.style.transition = progressPath.style.WebkitTransition =
			"stroke-dashoffset 10ms linear";
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.updateState);
	}

	render() {
		return (
			<div className={`scroll-top-div ${this.state.visibility}`}>
				<div onClick={this.scrollToTop} className="scroll-top-icon-div">
					<div className="scroll-top-icon">
						<p>▲</p>
					</div>
				</div>
				<button className="scroll-top-btn">
					<svg
						className="progress-circle svg-content"
						width="100%"
						height="100%"
						viewBox="-1 -1 102 102"
					>
						<path
							id="progress-path"
							style={{
								strokeDashoffset: `${this.state.progress}`,
							}}
							d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
						/>
					</svg>
				</button>
			</div>
		);
	}
}
