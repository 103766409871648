//@ts-check
import "../../resources/css/content/home.css";
import "../../resources/css/content/about-us.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import PageSegment from "../../components/content/PageSegment";

// circles section
import sectionOne from "../../resources/images/content/sections/about-us-section-1.svg";
import sectionTwo from "../../resources/images/content/sections/about-us-section-2.svg";
import sectionThree from "../../resources/images/content/sections/about-us-section-3.svg";

// partners section
import avantLogo from "../../resources/images/content/partners/avant-logo.svg";
import csobLogo from "../../resources/images/content/partners/csob-logo.svg";
import jtLogo from "../../resources/images/content/partners/jt-banka-logo.svg";

//about us section
import aboutUsI from "../../resources/images/content/sections/about-us-i.svg";

const circlesSection = [
	{
		title: "home.section.first-title",
		text: "home.section.first-text",
		backgroundImage: sectionOne,
	},
	{
		title: "home.section.second-title",
		text: "home.section.second-text",
		backgroundImage: sectionTwo,
	},
	{
		title: "home.section.third-title",
		text: "home.section.third-text",
		backgroundImage: sectionThree,
	},
];

const partnersSection = [
	{ image: avantLogo, alt: "AVANT" },
	{ image: csobLogo, alt: "CSOB" },
	{ image: jtLogo, alt: "JTB" },
];

const AboutUs = (props) => {
	return (
		<React.Fragment>
			{/* strategy section */}
			<PageSegment
				customSegment={{
					childClass: "space-evenly-flex",
				}}
			>
				{circlesSection.map((sections, i) => {
					return (
						<div
							key={`aboutUsSection${i}`}
							className="background-about-us"
							style={{
								backgroundImage: `url(${sections.backgroundImage}`,
							}}
						>
							<div className="circle">
								<span className="circle-delimiter">
									<div className="circle-title">
										{props.t(sections.title)}
									</div>
									<div className="circle-content">
										<p>{props.t(sections.text)}</p>
									</div>
								</span>
							</div>
						</div>
					);
				})}
			</PageSegment>
			{/* about us section */}
			<PageSegment
				title={props.t("home.about")}
				image={aboutUsI}
				customSegment={{
					className: "gray-background",
					childClass: "blue-color",
				}}
			>
				<p style={{ fontWeight: 600 }}>
					{props.t("about-us.paragraph-1")}
				</p>
				<p>{props.t("about-us.paragraph-2")}</p>
				<p>{props.t("about-us.paragraph-3")}</p>
				<p>{props.t("about-us.paragraph-4")}</p>
			</PageSegment>
			{/* partners section */}
			<PageSegment
				title={props.t("about-us.partners")}
				customSegment={{
					className: "cyan-background",
					titleClass: "white-title",
					childClass: "space-evenly-grid-3",
				}}
			>
				{partnersSection.map((partner, i) => (
					<div
						key={`parnter${i}`}
						className={`partner-wrapper partner-${partner.alt.toLowerCase()}-logo`}
					>
						<img
							className="partner"
							src={partner.image}
							alt={partner.alt}
						/>
					</div>
				))}
			</PageSegment>
			{/* <React.Fragment>
				<p
					className="about-us-first-paragraph"
					dangerouslySetInnerHTML={{
						__html: props.t("about-us.paragraph-1"),
					}}
				/>
				<p>{props.t("about-us.paragraph-2")}</p>
				<p>{props.t("about-us.paragraph-3")}</p>
				<p>{props.t("about-us.paragraph-4")}</p>
			</React.Fragment> */}
			{/* LOGA */}
			{/* <p className="about-us-partner-text">
				{props.t("about-us.partners")}
			</p>
			<div className="about-us-partner-logo-wrapper">
				<a
					className="about-us-partner-link"
					href="https://www.avantfunds.cz/cs/"
				>
					<img
						src={avantLogo}
						className="about-us-partner-logo about-us-partner-logo-avant"
						alt={props.t("header.logo-home")}
					/>
				</a>
				<a
					className="about-us-partner-link"
					href="https://www.csob.cz/"
				>
					<img
						src={csobLogo}
						className="about-us-partner-logo about-us-partner-logo-csob"
						alt={props.t("header.logo-home")}
					/>
				</a>
				<a
					className="about-us-partner-link"
					href="https://www.jtbank.cz/"
				>
					<img
						src={jtLogo}
						className="about-us-partner-logo about-us-partner-logo-jtbank"
						alt={props.t("header.logo-home")}
					/>
				</a>
			</div> */}
		</React.Fragment>
	);
};

AboutUs.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(AboutUs);
