//@ts-check
import "../../resources/css/content/home.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import PageSegment from "../../components/content/PageSegment";
import VerticalSegment from "../../components/content/VerticalSegment";
import GraphPageStatement from "../../components/content/GraphPageStatement";

// circles section
import sectionOne from "../../resources/images/content/sections/about-us-section-1.svg";
import sectionTwo from "../../resources/images/content/sections/about-us-section-2.svg";
import sectionThree from "../../resources/images/content/sections/about-us-section-3.svg";

//about us section
import sectionLogo from "../../resources/images/content/sections/about-us-section-logo.svg";

// our team section
import ourTeam from "../../resources/images/content/sections/section-our-team.svg";
import michalvalentik from "../../resources/images/content/our-team/michal-valentik.png";
import miroslavkollar from "../../resources/images/content/our-team/miroslav-kollar.png";
import vazilhudak from "../../resources/images/content/our-team/vazil-hudak.png";

const circlesSection = [
	{
		title: "home.section.first-title",
		text: "home.section.first-text",
		backgroundImage: sectionOne,
	},
	{
		title: "home.section.second-title",
		text: "home.section.second-text",
		backgroundImage: sectionTwo,
	},
	{
		title: "home.section.third-title",
		text: "home.section.third-text",
		backgroundImage: sectionThree,
	},
];

const ourTeamSection = [
	{
		name: "our-team.michal-valentik-name",
		position: "our-team.michal-valentik-position",
		image: michalvalentik,
	},
	{
		name: "our-team.miroslav-kollar-name",
		position: "our-team.miroslav-kollar-position",
		image: miroslavkollar,
	},
	{
		name: "our-team.vazil-hudak-name",
		position: "our-team.vazil-hudak-position",
		image: vazilhudak,
	},
];

const Home = (props) => {
	return (
		<React.Fragment>
			<GraphPageStatement />
			{/* strategy section */}
			<PageSegment
				customSegment={{
					childClass: "circle-flex-gap space-evenly-flex",
				}}
			>
				{circlesSection.map((sections, i) => {
					return (
						<div
							id={"circle" + i}
							key={`aboutUsSection${i}`}
							className="background-about-us"
							style={{
								backgroundImage: `url(${sections.backgroundImage}`,
							}}
						>
							{/* TODO: NAVLINKS */}
							<div className="circle">
								<span className="circle-delimiter">
									<div className="circle-title">
										{props.t(sections.title)}
									</div>
									<div className="circle-content">
										<p>{props.t(sections.text)}</p>
									</div>
								</span>
							</div>
						</div>
					);
				})}
			</PageSegment>
			{/* about us section */}
			<VerticalSegment
				title={props.t("home.about")}
				image={sectionLogo}
				navLink="/about-us"
				customSegment={{
					className: "cyan-background",
					titleClass: "white-title",
				}}
			>
				{props.t("about-us.paragraph-1")}
			</VerticalSegment>
			{/* our team section */}
			<PageSegment
				title={props.t("home.our-team")}
				image={ourTeam}
				navLink="/team"
				customSegment={{
					// className: "gray-background",
					childClass: "space-evenly-flex",
				}}
			>
				{ourTeamSection.map((teamMember, i) => {
					return (
						<div
							className="home-team-member"
							key={`teamMember${i}`}
						>
							<div className="home-team-member-image-wrapper">
								<img
									className="home-team-member-image"
									src={teamMember.image}
									alt={props.t(teamMember.name)}
								/>
							</div>
							<div className="home-team-member-name">
								{props.t(teamMember.name)}
							</div>
							<div className="home-team-member-position">
								{props.t(teamMember.position)}
							</div>
						</div>
					);
				})}
			</PageSegment>
		</React.Fragment>
	);
};

Home.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(Home);
