//@ts-check
import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

const NotFound = (props) => {
	return (
		<div className="not-found">
			<h1>{props.t("errors.not-found")}</h1>
		</div>
	);
};

NotFound.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(NotFound);
