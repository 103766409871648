//@ts-check
import "../../resources/css/content/contact-us.css";

import PageSegment from "../../components/content/PageSegment";
import ContactForm from "../../components/specific/ContactForm";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import contactImage from "../../resources/images/content/sections/contact-us-contact-section-logo.svg";

const ContactUs = (props) => {
	// const ContactFormTranslated = translate(ContactForm);
	return (
		<PageSegment
			title={props.t("contact-us.contacts")}
			image={contactImage}
			customSegment={{
				childClass: "blue-color",
			}}
		>
			<div className="contact-us">
				{/* TITLE */}
				<div className="contact-us-name">
					{props.t("contact-us.company-name")}
				</div>
				<div className="contact-us-contacts">
					{/* ADDRESS */}
					<div className="contact-us-contacts-section">
						<div>{props.t("contact-us.address-value-0")}</div>
						<div>{props.t("contact-us.address-value-1")}</div>
					</div>
					{/* EMAIL */}
					{/* <a href='mailto:info@4gimel.cz'>info@4gimel.cz</a> */}
					<div className="contact-us-contacts-section">
						<div>
							{props.t("contact-us.email-title")}{" "}
							<a href="mailto:info@4gimel.cz">
								{props.t("contact-us.email-value")}
							</a>
						</div>
						{/* <div>
							{props.t("contact-us.phone-title")}
							{" +420 000 000 000"}
						</div> */}
					</div>
				</div>
				{/* MAPA */}
				{/* <iframe
					title={"google-map"}
					className="contact-us-map"
					width="100%"
					height="650px"
					style={{ border: 0, display: "block" }}
					allowFullScreen={true}
					loading="lazy"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.0075387128804!2d14.434279315886206!3d50.086145721428245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b949425e8c54b%3A0xfcd015ee414fae71!2sU%20Bulhara%201611%2C%20110%2000%20Nov%C3%A9%20M%C4%9Bsto!5e0!3m2!1sen!2scz!4v1627502807744!5m2!1sen!2scz"
				></iframe> */}
				<div className="contact-us-contact-from">
					<div className="contact-us-contact-form-title">
						{props.t("contact-us.contact-form.title")}
					</div>
					<ContactForm />
				</div>
			</div>
		</PageSegment>
	);
};

ContactUs.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(ContactUs);
