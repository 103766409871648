//@ts-check
import "react-loading-skeleton/dist/skeleton.css";
import { DB_URL, IS_DEVELOP } from "../../pages/Main.js";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import Skeleton from "react-loading-skeleton";

class PerformanceHistoricTable extends React.Component {
	constructor(props) {
		super(props);
		this.elementRef = React.createRef();
		this.state = {
			loading: true,
			query: {
				queryCount: 0,
				queryResult: {
					summary: [{ performance_date: "", performance_total: "" }],
				},
			},
		};

		this.setQueryData = this.setQueryData.bind(this);
	}

	async setQueryData(queryData) {
		if (this.elementRef.current != null) {
			this.setState(() => ({
				loading: false,
				query: {
					queryCount: queryData.itemCount,
					queryResult: queryData.body,
				},
			}));
		}
	}

	async componentDidMount() {
		const url = `${DB_URL}/services/api/performanceHistoric.php${
			IS_DEVELOP ? "?develop" : ""
		}`;
		try {
			await fetch(url)
				.then((response) => response.json())
				.then(async (data) => {
					await this.setQueryData(data);
				});
		} catch (e) {
			console.log("URL: ", url);
			await fetch(url)
				.then((response) => response.body)
				.then((data) => console.log(data));
			console.error("Error: ", e);
		}
	}

	formatNumber(number, precision) {
		return (
			(number >= 0 ? "+ " : "- ") +
			(precision
				? Math.abs(number).toFixed(precision)
				: Math.abs(number)) +
			" %"
		);
	}

	formatDate(date) {
		let splitDate = date.split("-");
		return `${splitDate[2]}. ${splitDate[1]}. ${splitDate[0]}`;
	}

	renderYearLine(leftValue, rightValue, suffix) {
		return (
			<div>
				<div className="for-investors-table-row">
					<span className="for-investors-table-columns for-investors-table-column-left">
						{this.state.loading ? (
							<Skeleton />
						) : (
							this.props.t("for-investors.performance-year") +
							" " +
							leftValue
						)}
						{this.state.loading ? null : suffix}
					</span>
					<span className="for-investors-table-columns for-investors-table-column-right">
						{this.state.loading ? (
							<Skeleton />
						) : (
							this.formatNumber(rightValue, 2)
						)}
					</span>
				</div>
				<hr className="for-investors-table-divider" />
			</div>
		);
	}

	renderValueLine(leftValue, rightValue, suffix) {
		return (
			<div>
				<div className="for-investors-table-row">
					<span className="for-investors-table-columns for-investors-table-column-left for-investors-table-bold">
						{this.state.loading ? <Skeleton /> : leftValue}
						{this.state.loading ? null : suffix}
					</span>
					<span className="for-investors-table-columns for-investors-table-column-right for-investors-table-bold">
						{this.state.loading ? (
							<Skeleton />
						) : (
							this.formatNumber(rightValue, 2)
						)}
					</span>
				</div>
				<hr className="for-investors-table-divider" />
			</div>
		);
	}

	render() {
		let itemCountArray = [];
		for (var i = this.state.query.queryCount - 1; i >= 0; i--) {
			itemCountArray.push(i);
		}

		return (
			<div className="performance-historic-table">
				<div className="for-investors-title-box">
					{`${this.props.t("for-investors.performance-title")} `}
					{this.state.loading ? (
						<Skeleton />
					) : (
						this.formatDate(
							this.state.query.queryResult.summary[0]
								.performance_date
						)
					)}
				</div>
				<div ref={this.elementRef}>
					<div>
						{itemCountArray.map((itemNumber) => {
							return (
								<React.Fragment key={itemNumber}>
									{this.renderYearLine(
										this.state.query.queryResult.years[
											itemNumber
										].year,
										this.state.query.queryResult.years[
											itemNumber
										].year_value,
										parseInt(
											this.state.query.queryResult.years[
												itemNumber
											].year
										) === 2018 ? (
											<span className="for-investors-description">
												{` ${this.props.t(
													"for-investors.performance-calculation-description"
												)}`}
											</span>
										) : null
									)}
								</React.Fragment>
							);
						})}
						{this.renderValueLine(
							this.props.t(
								"for-investors.performance-since-inception"
							),
							this.state.query.queryResult.summary[0]
								.performance_total
						)}
						{this.renderValueLine(
							this.props.t(
								"for-investors.performance-annualized"
							),
							this.state.query.queryResult.summary[0]
								.performance_annual,
							<React.Fragment>
								<span className="for-investors-suffix">
									{` ${this.props.t(
										"for-investors.performance-pa"
									)}`}
								</span>
								<span className="for-investors-description">
									{` ${this.props.t(
										"for-investors.performance-calculation-description"
									)}`}
								</span>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}

PerformanceHistoricTable.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(PerformanceHistoricTable);
