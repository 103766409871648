//@ts-check
// import "../resources/css/content.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import { ScrollToTopButton } from "../content/ScrollToTopButton";

class Content extends React.Component {
	render() {
		return (
			<div className="content">
				{this.props.children}
				<ScrollToTopButton />
			</div>
		);
	}
}

Content.propTypes = {
	children: PropTypes.any.isRequired,
	t: PropTypes.any.isRequired,
};

export default translate(Content);
