//@ts-check
// import "../resources/css/login.css";

import React from "react";
import PropTypes from "prop-types";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
	validateCaptcha,
} from "react-simple-captcha";

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			captchaValue: "",
		};

		this.validateCaptcha = this.validateCaptcha.bind(this);
		this.setCaptchaValue = this.setCaptchaValue.bind(this);
	}

	componentDidMount() {
		loadCaptchaEnginge(6, `lightBlue`);
	}

	render() {
		return (
			<div className="login-wrapper">
				<div className="login-info-text">
					{this.props.t("login.info-text")}
				</div>
				<div className="captcha-wrapper">
					<LoadCanvasTemplate
						reloadText={this.props.t("login.captcha-reload")}
					/>
				</div>
				<input
					className="captcha-input"
					type="text"
					onChange={this.setCaptchaValue}
					value={this.state.captchaValue}
				/>
				<div className="captcha-button-wrapper">
					<button
						className="captcha-button"
						onClick={this.validateCaptcha}
					>
						{this.props.t("login.button")}
					</button>
				</div>
			</div>
		);
	}

	setCaptchaValue(event) {
		this.setState(() => ({
			captchaValue: event.target.value,
		}));
	}

	validateCaptcha() {
		if (validateCaptcha(this.state.captchaValue, false)) {
			sessionStorage.setItem(
				"session",
				JSON.stringify(this.state.captchaValue)
			);
			window.location.href = "/";
		} else {
			alert(this.props.t("login.captcha-error"));
			loadCaptchaEnginge(6);
		}
	}
}

Login.propTypes = {
	t: PropTypes.any.isRequired,
};
