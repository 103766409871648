//@ts-check
import "../../../resources/css/side-drawer.css";

import React from "react";
import PropTypes from "prop-types";

class SideDrawerBackdrop extends React.Component {
	render() {
		return (
			<div
				className="side-drawer-backdrop"
				onClick={this.props.onClickAction}
			/>
		);
	}
}

SideDrawerBackdrop.propTypes = {
	onClickAction: PropTypes.any.isRequired,
};

export default SideDrawerBackdrop;
