//@ts-check
import "../../resources/css/components/graph-page-statement.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import arrowDownImg from "../../resources/images/components/graph-statement/arrowDown.svg";

class GraphPageStatement extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="statement">
					<div className="width-delimiter">
						<div className="graph-statement">
							<div className="graph-statement-title">
								{this.props.t("graph-section.title")}
							</div>
							<div className="graph-statement-text">
								<p>{this.props.t("graph-section.text")}</p>
								<div
									className="arrow-down-wrapper"
									onClick={() => {
										document
											.getElementById("circle0")
											.scrollIntoView();
									}}
								>
									<img src={arrowDownImg} alt="arrow-down" />
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="statement statement-mobile"></div> */}
			</React.Fragment>
		);
	}
}

GraphPageStatement.propTypes = {
	t: PropTypes.any.isRequired,
	children: PropTypes.any,
};

export default translate(GraphPageStatement);
