//@ts-check
import "../../resources/css/content/alfa.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import PageSegment from "../../components/content/PageSegment";

import investmentStrategy from "../../resources/images/content/alfa/invest_strategy.png";
import opportunisticPortfolio from "../../resources/images/content/alfa/opportunistic_portfolio.png";

import alfaImage from "../../resources/images/content/sections/alfa-section.svg";

const Alfa = (props) => {
	return (
		<React.Fragment>
			<PageSegment
				title={props.t("header.menu-alfa")}
				image={alfaImage}
				customSegment={{
					childClass: "blue-color",
				}}
			>
				<div className="alfa">
					<div className="alfa-title">
						{props.t("alfa.investment-objectives-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t("alfa.investment-objectives-text"),
						}}
					/>
					<div className="alfa-title">
						{props.t("alfa.investment-strategy-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t("alfa.investment-strategy-text"),
						}}
					/>
				</div>
			</PageSegment>
			<PageSegment
				customSegment={{
					className: "gray-background",
				}}
			>
				<div className="alfa">
					<div className="alfa-graph-wrapper">
						<img
							className="alfa-graph"
							src={investmentStrategy}
							alt="investment strategy"
						/>
					</div>
				</div>
			</PageSegment>
			<PageSegment
				customSegment={{
					childClass: "blue-color",
				}}
			>
				<div className="alfa">
					<div className="alfa-title">
						{props.t("alfa.investment-strategy-sub1-title")}
					</div>
					<p className="alfa-text">
						{props.t("alfa.investment-strategy-sub1-text-0-text")}
					</p>
					<div className="alfa-subtitle">
						{props.t("alfa.investment-strategy-sub1-text-1-title")}
					</div>
					<div className="alfa-subtext">
						<p>
							{props.t(
								"alfa.investment-strategy-sub1-text-1-text-0"
							)}
						</p>
						<p>
							{props.t(
								"alfa.investment-strategy-sub1-text-1-text-1"
							)}
						</p>
					</div>
					<div className="alfa-subtitle">
						{props.t("alfa.investment-strategy-sub1-text-2-title")}
					</div>
					<div className="alfa-subtext">
						<p>
							{props.t(
								"alfa.investment-strategy-sub1-text-2-text-0"
							)}
						</p>
						<p>
							{props.t(
								"alfa.investment-strategy-sub1-text-2-text-1"
							)}
						</p>
					</div>
					<div className="alfa-title">
						{props.t("alfa.investment-strategy-sub2-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t(
								"alfa.investment-strategy-sub2-text"
							),
						}}
					/>
					<div className="alfa-title">
						{props.t("alfa.investment-strategy-sub3-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t(
								"alfa.investment-strategy-sub3-text"
							),
						}}
					/>
					<div className="alfa-title">
						{props.t("alfa.investment-strategy-sub4-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t(
								"alfa.investment-strategy-sub4-text"
							),
						}}
					/>
				</div>
			</PageSegment>
			<PageSegment
				customSegment={{
					className: "gray-background",
				}}
			>
				<div className="alfa">
					<div className="alfa-graph-wrapper">
						<img
							className="alfa-graph"
							src={opportunisticPortfolio}
							alt="investment strategy"
						/>
					</div>
				</div>
			</PageSegment>
			<PageSegment
				customSegment={{
					childClass: "blue-color",
				}}
			>
				<div className="alfa">
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t(
								"alfa.investment-strategy-sub5-text"
							),
						}}
					/>
				</div>
			</PageSegment>
			<PageSegment
				customSegment={{
					className: "cyan-background",
					titleClass: "white-title",
				}}
			>
				{" "}
				<div className="alfa">
					<div className="alfa-title">
						{props.t("alfa.risk-management-title")}
					</div>
					<div
						className="alfa-text"
						dangerouslySetInnerHTML={{
							__html: props.t("alfa.risk-management-text"),
						}}
					/>
				</div>
			</PageSegment>
		</React.Fragment>
	);
};

Alfa.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(Alfa);
