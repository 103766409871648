//@ts-check
import "../../../resources/css/side-drawer.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import { NavLink } from "react-router-dom";

class SideDrawer extends React.Component {
	render() {
		return (
			<div
				className={`side-drawer ${
					this.props.showDrawer ? "side-drawer-open" : ""
				}`}
			>
				<div className="drawer-close-button-wrapper">
					<button
						className="drawer-close-button"
						onClick={this.props.closeAction}
					/>
				</div>

				{/* MENU SECTION */}
				<nav>
					<ul>
						{/* MENU */}
						{this.props.menuItems.map((menuItem, i) => {
							if (menuItem.path === "/") {
								return null;
							}
							return (
								<li
									key={`header${i}`}
									onClick={this.props.closeAction}
								>
									<NavLink
										to={menuItem.path}
										key={menuItem.path}
										activeClassName="header-navlink-active"
										exact
									>
										{this.props.t(
											menuItem["translation-string"]
										)}
									</NavLink>
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		);
	}
}

SideDrawer.propTypes = {
	t: PropTypes.any.isRequired,
	menuItems: PropTypes.any.isRequired,
	showDrawer: PropTypes.bool.isRequired,
	closeAction: PropTypes.any.isRequired,
};

export default translate(SideDrawer);
