//@ts-check
import "../../resources/css/header.css";

import React from "react";
import PropTypes from "prop-types";

import { NavLink, withRouter } from "react-router-dom";
import { getLanguage, setLanguage, translate } from "react-switch-lang";

import logo from "../../resources/images/logo/4G_logo.svg";
import { MULTI_LANGUAGE } from "../../pages/Main.js";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentLanguage: getLanguage(),
			// menuHidden: true,
			width: window.innerWidth,
		};

		this.handleSetLanguage = this.handleSetLanguage.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
	}

	handleSetLanguage(key) {
		setLanguage(key);
		this.setState({ currentLanguage: key });
	}

	updateDimensions() {
		this.setState(() => ({
			width: window.innerWidth,
		}));
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	renderLanguageSwitch() {
		if (MULTI_LANGUAGE) {
			return (
				<div className="header-language-wrapper">
					<span
						className={
							this.state.currentLanguage.includes(
								this.props.languages.czech
							)
								? "header-language-button-active"
								: ""
						}
						onClick={() =>
							this.handleSetLanguage(this.props.languages.czech)
						}
					>
						{this.props.t("header.language-cs")}
					</span>
					{"/"}
					<span
						className={
							this.state.currentLanguage.includes(
								this.props.languages.english
							)
								? "header-language-button-active"
								: ""
						}
						onClick={() =>
							this.handleSetLanguage(this.props.languages.english)
						}
					>
						{this.props.t("header.language-en")}
					</span>
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	render() {
		if (this.state.width < 1100) {
			// MOBILE HEADER
			return (
				<div id="header" className="header">
					<div className="width-delimiter">
						<div></div>
						{/* LOGO */}
						<div className="header-logo-wrapper">
							<NavLink to="/">
								<img
									src={logo}
									className="header-logo-image"
									alt={this.props.t("header.logo-home")}
								/>
							</NavLink>
						</div>
						<div className="menu-lang-button-group">
							{/* LANGUAGE SETTER */}
							{this.renderLanguageSwitch()}
							{/* MOBILE MENU BUTTON */}
							<label
								className="menu-button-wrapper"
								onClick={this.props.openDrawerAction}
							>
								<div className="menu-button" />
							</label>
						</div>
					</div>
				</div>
			);
		} else {
			// CLASSIC HEADER
			return (
				<div className="header">
					<div className="width-delimiter">
						{/* LOGO */}
						<div className="header-logo-wrapper">
							<NavLink to="/" className="header-logo-wrapper">
								<img
									src={logo}
									className="header-logo-image"
									alt={this.props.t("header.logo-home")}
								/>
							</NavLink>
						</div>
						{/* MENU SECTION */}
						<nav>
							<ul>
								{/* MENU */}
								{this.props.menuItems.map((menuItem, i) => {
									if (menuItem.path === "/") {
										return null;
									}
									return (
										<li key={`header${i}`}>
											<NavLink
												to={menuItem.path}
												key={menuItem.path}
												activeClassName="header-navlink-active"
												exact
											>
												{this.props.t(
													menuItem[
														"translation-string"
													]
												)}
											</NavLink>
										</li>
									);
								})}
							</ul>
						</nav>
						{/* LANGUAGE SETTER */}
						{this.renderLanguageSwitch()}
					</div>
				</div>
			);
		}
	}
}

// function determineMobileMenuTranslationKey(fullMenuProp, pathNameProp) {
// 	const menuItemArray = fullMenuProp.filter(
// 		(menuItems) => menuItems.path === pathNameProp
// 	);
// 	if (menuItemArray.length > 0) {
// 		return menuItemArray[0]["translation-string"];
// 	}
// 	return "";
// }

Header.propTypes = {
	t: PropTypes.any.isRequired,
	location: PropTypes.any.isRequired,
	menuItems: PropTypes.any.isRequired,
	languages: PropTypes.any.isRequired,
	openDrawerAction: PropTypes.any.isRequired,
};

export default translate(withRouter(Header));
