//@ts-check
import "../resources/css/fonts.css";
import "../resources/css/main.css";

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
	setTranslations,
	setDefaultLanguage,
	setLanguageCookie,
	translate,
	setLanguage,
} from "react-switch-lang";

import { init as emailjsInit } from "emailjs-com";
import emailjs from "../resources/system/private/EmailJS";

import en from "../resources/system/translations/en.json";
import cs from "../resources/system/translations/cs.json";

import Login from "../pages/system/Login";

import Header from "../components/page/Header";
import Content from "../components/page/Content";
import Footer from "../components/page/Footer";

import SideDrawer from "../components/page/side-drawer/SideDrawer";
import SideDrawerBackdrop from "../components/page/side-drawer/SideDrawerBackdrop";

import AboutUs from "../pages/public/AboutUs";
import Alfa from "../pages/public/Alfa";
import ContactUs from "../pages/public/ContactUs";
import ForInvestors from "../pages/public/ForInvestors";
import Home from "../pages/public/Home";
import NotFound from "../pages/public/NotFound";
import OurTeam from "../pages/public/OurTeam";
import Interview from "../pages/public/Interview";
import ScrollToTopOnLoad from "../components/specific/ScrollToTopOnLoad.js";

export const DB_URL = "https://db.4gimel.cz";
export const IS_DEVELOP = false;

setTranslations({ cs, en });

setDefaultLanguage("cs");

export const MULTI_LANGUAGE = false;
if (MULTI_LANGUAGE) {
	setLanguageCookie("language-4gimel", { secure: true });
} else {
	setLanguage("cs");
}

emailjsInit(emailjs.USER_ID);

export const languages = {
	english: "en",
	czech: "cs",
};

export const menu = [
	{
		path: "/",
		"translation-string": "header.menu-about-us",
		component: Home,
	},
	{
		path: "/about-us",
		"translation-string": "header.menu-about-us",
		component: AboutUs,
	},
	{
		path: "/team",
		"translation-string": "header.menu-our-team",
		component: OurTeam,
	},
	{
		path: "/alfa",
		"translation-string": "header.menu-alfa",
		component: Alfa,
	},
	{
		path: "/for-investors",
		"translation-string": "header.menu-for-investors",
		component: ForInvestors,
	},
	{
		path: "/contact",
		"translation-string": "header.menu-contact-us",
		component: ContactUs,
	},
];

export const hiddenMenu = [
	{
		path: "/interview/:member",
		component: Interview,
	},
];

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			useSession: IS_DEVELOP,
			sideDrawerOpen: false,
		};

		// scrolls page to top on page load
		// @ts-ignore
		window.scrollToView = (reference) => {
			var scrollToElement = document.getElementById(reference);
			var headerElement = document.getElementById("header");
			window.scrollTo(
				scrollToElement.offsetLeft,
				scrollToElement.offsetTop - (15 + headerElement.offsetHeight)
			);
		};

		this.drawerToggleClickHandler =
			this.drawerToggleClickHandler.bind(this);
		this.drawerCloseClickHandler = this.drawerCloseClickHandler.bind(this);
	}

	drawerToggleClickHandler() {
		this.setState((prevState) => {
			return { sideDrawerOpen: !prevState.sideDrawerOpen };
		});
	}

	drawerCloseClickHandler() {
		this.setState(() => {
			return { sideDrawerOpen: false };
		});
	}

	render() {
		if (!sessionStorage.getItem("session") && this.state.useSession) {
			const LoginTranslated = translate(Login);
			return <LoginTranslated />;
		}
		return (
			<Router>
				<ScrollToTopOnLoad />
				<SideDrawer
					menuItems={menu}
					showDrawer={this.state.sideDrawerOpen}
					closeAction={this.drawerCloseClickHandler}
				/>
				{this.state.sideDrawerOpen ? (
					<React.Fragment>
						<SideDrawerBackdrop
							onClickAction={this.drawerCloseClickHandler}
						/>
					</React.Fragment>
				) : null}
				<Header
					menuItems={menu}
					languages={languages}
					openDrawerAction={this.drawerToggleClickHandler}
				/>
				<Content>
					<Switch>
						{menu.map((menuItem, i) => {
							return (
								<Route
									key={`main${i}`}
									path={menuItem.path}
									exact={menuItem.path === "/"}
								>
									<menuItem.component />
								</Route>
							);
						})}
						{hiddenMenu.map((hiddenMenuItem, i) => {
							return (
								<Route
									key={`hidden${i}`}
									path={hiddenMenuItem.path}
									exact={hiddenMenuItem.path === "/"}
								>
									<hiddenMenuItem.component />
								</Route>
							);
						})}
						<Route component={translate(NotFound)} />
					</Switch>
				</Content>
				<Footer />
			</Router>
		);
	}
}

export default Main;
