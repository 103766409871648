//@ts-check
import "../../resources/css/components/main-segment.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import { NavLink } from "react-router-dom";

class PageSegment extends React.Component {
	renderTitleAndImage() {
		return (
			<React.Fragment>
				{this.props.image ? (
					<div className="segment-image-wrapper">
						<img
							src={this.props.image}
							className="segment-image"
							alt={this.props.title}
						/>
					</div>
				) : null}
				{this.props.title ? (
					<div
						className={`segment-title ${
							this.props.customSegment
								? this.props.customSegment.titleClass
								: ""
						}`}
					>
						{this.props.title}
					</div>
				) : null}
			</React.Fragment>
		);
	}

	render() {
		return (
			<div
				className={`segment ${
					this.props.customSegment
						? this.props.customSegment.className
						: ""
				}`}
			>
				{this.props.navLink ? (
					<NavLink
						className="segment-navlink"
						to={this.props.navLink}
						key={this.props.navLink}
						activeClassName="header-navlink-active"
						exact
					>
						{this.renderTitleAndImage()}
					</NavLink>
				) : (
					this.renderTitleAndImage()
				)}
				<div className="width-delimiter">
					<div
						className={`segment-child ${
							this.props.customSegment
								? this.props.customSegment.childClass
								: ""
						}`}
					>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

PageSegment.propTypes = {
	t: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired,
	customSegment: PropTypes.any,
	image: PropTypes.any,
	title: PropTypes.string,
	navLink: PropTypes.string,
};

export default translate(PageSegment);
