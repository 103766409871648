//@ts-check
import "../../resources/css/content/our-team.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import { NavLink } from "react-router-dom";

import PageSegment from "../../components/content/PageSegment";

// section image
import ourTeamImage from "../../resources/images/content/sections/section-our-team.svg";

// our team images
import michalvalentikimg from "../../resources/images/content/our-team/michal-valentik.png";
import miroslavkollarimg from "../../resources/images/content/our-team/miroslav-kollar.png";
import vazilhudakimg from "../../resources/images/content/our-team/vazil-hudak.png";

import linkedin from "../../resources/images/content/our-team/linkedin-logo.svg";

const ourTeam = [
	{
		translation: "michal-valentik",
		descriptionParagraphs: [1],
		image: michalvalentikimg,
		linkedin: "https://cz.linkedin.com/in/michal-valent%C3%ADk-538109137",
		interview: `/interview/michal-valentik`,
	},

	{
		translation: "miroslav-kollar",
		descriptionParagraphs: [2],
		image: miroslavkollarimg,
		linkedin: "https://cz.linkedin.com/in/miroslavkollar",
		interview: `/interview/miroslav-kollar`,
	},
	{
		translation: "vazil-hudak",
		descriptionParagraphs: [2],
		image: vazilhudakimg,
		linkedin:
			"https://cz.linkedin.com/in/vazil-hudak-b6994310?trk=pub-pbmap",
		interview: "",
	},
];

const OurTeam = (props) => {
	return (
		<PageSegment
			image={ourTeamImage}
			title={props.t("home.our-team")}
			customSegment={{
				// className: "gray-background",
				childClass: "blue-color",
			}}
		>
			{ourTeam.map((member, i) => {
				let renderInterview = true;
				const interviewPreviewEnum = `our-team.${member.translation}-interview`;
				const interviewPreviewTranslation =
					props.t(interviewPreviewEnum);
				if (
					interviewPreviewEnum === interviewPreviewTranslation ||
					!interviewPreviewTranslation
				) {
					renderInterview = false;
				}

				return (
					<div className="team" key={i}>
						<div className="team-image-wrapper">
							<img
								className="team-image"
								src={member.image}
								alt={member.translation}
							/>
						</div>
						<div>
							<div className="team-member-name">
								{props.t(`our-team.${member.translation}-name`)}
							</div>
							<div className="team-member-position">
								{props.t(
									`our-team.${member.translation}-position`
								)}
							</div>
							<div
								className={`team-member-content ${
									renderInterview
										? ""
										: "team-member-content-single"
								}`}
							>
								<div className="team-member-description">
									{member.descriptionParagraphs.map(
										(paragraphs) => {
											let paragraphConstant = [];
											for (
												let i = 0;
												i < paragraphs;
												i++
											) {
												paragraphConstant.push(
													<p
														key={`parag-${member.translation}-${i}`}
													>
														{props.t(
															`our-team.${member.translation}-description-${i}`
														)}
													</p>
												);
											}
											return paragraphConstant;
										}
									)}
								</div>
								{renderInterview ? (
									<div className="team-member-description">
										<div className="team-member-interview">
											{props.t("our-team.interview")}
										</div>
										<p>{interviewPreviewTranslation}</p>
										<div className="team-member-interview team-member-interview-link">
											<NavLink to={member.interview}>
												{props.t(
													"our-team.read-interview"
												)}
											</NavLink>
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className="team-member-links">
							<div />
							<div className="team-linkedin-wrapper">
								<a
									href={member.linkedin}
									target={"_blank"}
									rel={"noreferrer"}
								>
									<img
										className="linkedin-image"
										src={linkedin}
										alt={member.translation}
									/>
								</a>
							</div>
						</div>
					</div>
				);
			})}
		</PageSegment>
	);
};

OurTeam.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(OurTeam);
