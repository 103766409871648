//@ts-check
import "../../resources/css/components/vertical-segment.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import { NavLink } from "react-router-dom";

class VerticalSegment extends React.Component {
	renderTitleAndImage() {
		return (
			<React.Fragment>
				{this.props.image ? (
					<div className="vertical-segment-image-wrapper">
						<img
							src={this.props.image}
							className="vertical-segment-image"
							alt={this.props.title}
						/>
					</div>
				) : null}
				{this.props.title ? (
					<div
						className={`vertical-segment-title ${
							this.props.customSegment
								? this.props.customSegment.titleClass
								: ""
						}`}
					>
						{this.props.title}
					</div>
				) : null}
			</React.Fragment>
		);
	}

	render() {
		return (
			<div
				className={`vertical-segment ${
					this.props.customSegment
						? this.props.customSegment.className
						: ""
				}`}
			>
				<div className="width-delimiter">
					<div className="vertical-segment-image-title-section">
						{this.props.navLink ? (
							<NavLink
								className="vertical-segment-navlink"
								to={this.props.navLink}
								key={this.props.navLink}
								activeClassName="header-navlink-active"
								exact
							>
								{this.renderTitleAndImage()}
							</NavLink>
						) : (
							this.renderTitleAndImage()
						)}
					</div>
					<div
						className={`vertical-segment-child ${
							this.props.customSegment
								? this.props.customSegment.childClass
								: ""
						}`}
					>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

VerticalSegment.propTypes = {
	t: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired,
	customSegment: PropTypes.any,
	image: PropTypes.any,
	title: PropTypes.string,
	navLink: PropTypes.string,
};

export default translate(VerticalSegment);
