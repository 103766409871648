//@ts-check
import "../../resources/css/footer.css";

import React from "react";
import PropTypes from "prop-types";

import { translate } from "react-switch-lang";
// import { NavLink } from "react-router-dom";
// import { menu } from "../resources/data/Menu";

import logo from "../../resources/images/logo/4G_sign.svg";

const Footer = (props) => {
	return (
		<div className="footer">
			<div className="width-delimiter">
				<div className="footer-logo-wrapper">
					<img
						src={logo}
						className="footer-logo-image"
						alt={props.t("header.logo-home")}
					/>
				</div>
				<div className="footer-content-text">
					<div>{props.t("footer.company-name")}</div>
					<div>
						{`${props.t("footer.company-email")} `}
						<span className="footer-divider">|</span>
						{` ${props.t("footer.company-address1")} ${props.t(
							"footer.company-address2"
						)}`}
					</div>
					<div>{`${props.t(
						"footer.company-legal-confirmation"
					)} `}</div>
				</div>
			</div>
		</div>
	);
};

Footer.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(Footer);
