//@ts-check
import "../../resources/css/content/for-investors.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

import PageSegment from "../../components/content/PageSegment";

import PerformanceHistoricTable from "../../components/specific/PerformanceHistoricTable";
import PerformanceSummaryTable from "../../components/specific/PerformanceSummaryTable";

import downloadSection from "../../resources/images/content/sections/for-investors-download.svg";
import downloadImage from "../../resources/images/components/for-investors-download-book.svg";

const downloadButtons = [
	{
		translation: "for-investors.download-factsheet",
		file: "for-investors.download.factsheet-file",
		fileName: "for-investors.download.factsheet",
	},
	{
		translation: "for-investors.download-statut",
		file: "for-investors.download.subfund-file",
		fileName: "for-investors.download.subfund",
	},
	{
		translation: "for-investors.download-information",
		file: "for-investors.download.keyinfo-file",
		fileName: "for-investors.download.keyinfo",
	},
];

const ForInvestors = (props) => {
	return (
		<React.Fragment>
			{/* TABLES */}
			<PageSegment>
				{/* PERFORMANCE TABLE */}
				<PerformanceHistoricTable />
			</PageSegment>
			<PageSegment>
				{/* STOCK VALUE */}
				<PerformanceSummaryTable />
			</PageSegment>
			{/* DOWNLOADS */}
			<PageSegment
				title={props.t("for-investors.download-title")}
				image={downloadSection}
				customSegment={{
					childClass: "blue-color",
				}}
			>
				<div className="for-investors-monthly-report">
					<a
						href={`/files/${props.t(
							"for-investors.download.report-file"
						)}`}
						download={`${props.t("for-investors.download.report")}`}
					>
						<div className="for-investors-monthly-report-button">
							{props.t("for-investors.monthly-report-title")}
						</div>
					</a>
				</div>
				<div className="for-investors-download-list">
					{/* DOWNLOAD BUTTONS */}
					{downloadButtons.map((downloadButton, i) => {
						return (
							<div
								key={`downloadButton${i}`}
								className="for-investors-download-item"
							>
								<div className="for-investors-download-list-title">
									{props.t(downloadButton.translation)}
								</div>

								<a
									className="for-investors-download-list-item"
									href={`/files/${props.t(
										downloadButton.file
									)}`}
									download={`${props.t(
										downloadButton.fileName
									)}`}
								>
									<div className="for-investors-download-list-image-wrapper">
										<img
											className="for-investors-download-list-image"
											src={downloadImage}
											alt={props.t(
												downloadButton.translation
											)}
										/>
									</div>
								</a>
							</div>
						);
					})}
				</div>
			</PageSegment>
			{/* IMPORTANT INFORMATION */}
			<PageSegment
				title={props.t("for-investors.information-title")}
				customSegment={{
					className: "cyan-background",
					titleClass: "white-title",
				}}
			>
				<span
					className="for-investors-important-information"
					dangerouslySetInnerHTML={{
						__html: props.t(
							"for-investors.information-description"
						),
					}}
				/>
			</PageSegment>
		</React.Fragment>
	);
};

ForInvestors.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(ForInvestors);
