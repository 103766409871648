//@ts-check
import "react-loading-skeleton/dist/skeleton.css";
import { DB_URL, IS_DEVELOP } from "../../pages/Main.js";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";
import Skeleton from "react-loading-skeleton";

class PerformanceSummaryTable extends React.Component {
	constructor(props) {
		super(props);
		this.elementRef = React.createRef();
		this.state = {
			loading: true,
			query: {
				queryCount: 0,
				queryResult: [{ to_date_date: "", to_date_price: 0 }],
			},
		};

		this.setQueryData = this.setQueryData.bind(this);
	}

	async setQueryData(queryData) {
		if (this.elementRef.current != null) {
			this.setState(() => ({
				loading: false,
				query: {
					queryCount: queryData.itemCount,
					queryResult: queryData.body,
				},
			}));
		}
	}

	componentDidMount() {
		fetch(
			`${DB_URL}/services/api/performanceSummary.php${
				IS_DEVELOP ? "?develop" : ""
			}`
		)
			.then((response) => response.json())
			.then(async (data) => {
				await this.setQueryData(data);
			});
	}

	formatDate(date) {
		let splitDate = date.split("-");
		return `${splitDate[2]}. ${splitDate[1]}. ${splitDate[0]}`;
	}

	formatNumber(number, precision) {
		return Math.abs(number).toFixed(precision);
	}

	renderValueLine(leftValue, rightValue) {
		return (
			<div>
				<div className="for-investors-table-row">
					<span className="for-investors-table-columns for-investors-table-column-left">
						{this.state.loading ? <Skeleton /> : leftValue}
					</span>
					<span className="for-investors-table-columns for-investors-table-column-right">
						{this.state.loading ? <Skeleton /> : rightValue}
					</span>
				</div>
				<hr className="for-investors-table-divider" />
			</div>
		);
	}

	render() {
		return (
			<div className="performance-summary-table">
				<div className="for-investors-title-box">
					{this.props.t("for-investors.value-title")}
				</div>
				<div ref={this.elementRef}>
					<div>
						{this.renderValueLine(
							this.props.t("for-investors.value-to-date"),
							this.state.query.queryResult.length > 0
								? this.formatDate(
										this.state.query.queryResult[0]
											.to_date_date
								  )
								: ""
						)}
						{this.renderValueLine(
							this.props.t("for-investors.value-price-per-share"),
							this.state.query.queryResult.length > 0
								? this.formatNumber(
										this.state.query.queryResult[0]
											.to_date_price,
										4
								  )
								: ""
						)}
					</div>
				</div>
			</div>
		);
	}
}

PerformanceSummaryTable.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(PerformanceSummaryTable);
