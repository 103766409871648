//@ts-check
import "../../resources/css/components/specific/contact-form.css";

import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-switch-lang";

// import ContentBox from "./ContentBox";
// import Button from "./basic/Button";

import { send } from "emailjs-com";
import emailjs from "../../resources/system/private/EmailJS";

const stateNames = ["name", "email", "text", "gdpr"];

class ContactForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: { value: "", valid: true },
			email: { value: "", valid: true },
			text: { value: "", valid: true },
			gdpr: { value: false, valid: true },
			invalidStates: 0,
			sent: false,
		};

		this.setName = this.setName.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.setText = this.setText.bind(this);
		this.checkGdpr = this.checkGdpr.bind(this);
		this.validateAndSendEmail = this.validateAndSendEmail.bind(this);

		this.validateEmail = this.validateEmail.bind(this);
	}

	async setName(event) {
		await this.setState(() => ({
			name: { value: event.target.value, valid: true },
			invalidStates: 0,
			sent: false,
		}));
	}

	async setEmail(event) {
		await this.setState(() => ({
			email: { value: event.target.value, valid: true },
			invalidStates: 0,
			sent: false,
		}));
	}

	async setText(event) {
		await this.setState(() => ({
			text: { value: event.target.value, valid: true },
			invalidStates: 0,
			sent: false,
		}));
	}

	async checkGdpr() {
		await this.setState((prevState) => ({
			gdpr: { value: !prevState.gdpr.value, valid: true },
			invalidStates: 0,
			sent: false,
		}));
	}

	async validateAndSendEmail() {
		await this.setState((prevState) => ({
			name: {
				value: prevState.name.value,
				valid: this.validateText(prevState.name.value),
			},
			email: {
				value: prevState.email.value,
				valid: this.validateEmail(),
			},
			text: {
				value: prevState.text.value,
				valid: this.validateText(prevState.text.value),
			},
			gdpr: { value: prevState.gdpr.value, valid: prevState.gdpr.value },
			invalidStates: 0,
		}));

		let count = this.state.invalidStates;
		for (let stateName of stateNames) {
			if (!this.state[stateName].valid) {
				count++;
			}
		}
		await this.setState(() => ({
			invalidStates: count,
			sent: count > 0 ? false : true,
		}));

		if (this.state.sent) {
			var templateParams = {
				from_name: this.state.name.value,
				from_email: this.state.email.value,
				message: this.state.text.value,
			};

			await this.setState(() => ({
				name: { value: "", valid: true },
				email: { value: "@", valid: true },
				text: { value: "", valid: true },
				gdpr: { value: false, valid: true },
			}));

			send(
				emailjs.SERVICE_ID,
				emailjs.TEMPLATE_ID,
				templateParams,
				emailjs.USER_ID
			).then(
				function (response) {
					console.log("Email sent!", response.status, response.text);
				},
				function (error) {
					console.log("Can not send email right now...", error);
				}
			);
		}
	}

	validateEmail() {
		return this.state.email.value.match(
			"[A-Za-z0-9.-]*@[A-Za-z0-9.-]*\\.[A-Za-z]*"
		);
	}

	validateText(text) {
		if (text.trim() === "") {
			return false;
		}
		return true;
	}

	render() {
		return (
			<React.Fragment>
				<div
					className={`contact-form-info ${
						this.state.invalidStates === 0 && this.state.sent
							? "contact-form-sent"
							: ""
					} ${
						this.state.invalidStates > 0 && !this.state.sent
							? "contact-form-invalid"
							: ""
					} ${
						this.state.sent || this.state.invalidStates > 0
							? ""
							: "contact-form-hidden"
					}`}
				>
					{this.state.invalidStates === 0 && this.state.sent
						? this.props.t("contact-us.contact-form.confirmation")
						: ""}
					{this.state.invalidStates > 0 && !this.state.sent
						? `${this.state.invalidStates} ${
								this.state.invalidStates === 1
									? this.props.t(
											"contact-us.contact-form.needs-attention-singular"
									  )
									: this.props.t(
											"contact-us.contact-form.needs-attention-plural"
									  )
						  }`
						: ""}
				</div>
				<div className="contact-form-line-wrapper contact-form-personal-information">
					<div className="contact-form-input-line">
						<input
							className={`contact-form-fields-style contact-form-input ${
								this.state.name.valid
									? ""
									: "contact-form-field-invalid"
							}`}
							type="text"
							placeholder={this.props.t(
								"contact-us.contact-form.name"
							)}
							value={this.state.name.value}
							onChange={this.setName}
						/>
					</div>
				</div>
				<div className="contact-form-line-wrapper contact-form-personal-information">
					<div className="contact-form-input-line">
						<input
							className={`contact-form-fields-style contact-form-input ${
								this.state.email.valid
									? ""
									: "contact-form-field-invalid"
							}`}
							type="text"
							placeholder={this.props.t(
								"contact-us.contact-form.email"
							)}
							value={this.state.email.value}
							onChange={this.setEmail}
						/>
					</div>
				</div>
				<div className="contact-form-line-wrapper">
					<span className="contact-form-line contact-form-text-area">
						<textarea
							className={`contact-form-fields-style contact-form-text-area ${
								this.state.text.valid
									? ""
									: "contact-form-field-invalid"
							}`}
							placeholder={this.props.t(
								"contact-us.contact-form.message"
							)}
							value={this.state.text.value}
							onChange={this.setText}
						/>
					</span>
				</div>
				<div className="contact-form-line-wrapper contact-form-last-row">
					<span className="contact-form-line contact-form-checkbox">
						<input
							className={
								"contact-form-fields-style contact-form-checkbox-box"
							}
							type="checkbox"
							checked={this.state.gdpr.value}
							onChange={this.checkGdpr}
						/>
						<span
							className={`contact-form-checkbox-text ${
								this.state.gdpr.valid
									? ""
									: "contact-form-checkbox-invalid"
							}`}
							dangerouslySetInnerHTML={{
								__html: this.props.t(
									"contact-us.contact-form.gdpr"
								),
							}}
						/>
					</span>
					<span className="contact-form-line contact-form-button-wrapper">
						<button
							className="contact-form-button"
							onClick={this.validateAndSendEmail}
						>
							{this.props.t("contact-us.contact-form.send")}
						</button>
					</span>
				</div>
			</React.Fragment>
			// 	}
			// />
		);
	}
}

ContactForm.propTypes = {
	t: PropTypes.any.isRequired,
};

export default translate(ContactForm);
